package auth

import database.CustomerSession
import io.github.jan.supabase.gotrue.auth
import io.github.jan.supabase.gotrue.providers.builtin.Email
import model.ServerLoginResponse
import model.UserLoginParams
import session.HttpSession
import utils.myLog

suspend fun loginUser(userParams: UserLoginParams): ServerLoginResponse {
    val client = HttpSession.getClient()
    var message = "Login successful"

    myLog("Sign in ${userParams.email}")
    try {
        client.auth.signInWith(Email) {
            email = userParams.email
            password = userParams.password
            captchaToken = userParams.captchaToken
        }
    } catch (e: Exception) {
        val msg = e.message
        val cause = e.cause

        myLog("Sign in exception: $e")

        // Supabase returns a message with the full URL, token, etc.
        // Convert this to a more user-friendly message.
        message = if (msg != null) {
            if (msg.contains("invalid_grant")) {
                // pull out the actual error from between the parenthesis
                val start = msg.indexOf("(")
                val end = msg.indexOf(")")
                msg.substring(start + 1, end)
            } else if (msg.contains("captcha")) {
                "Captcha verification error. Please reload the page and try again."
            } else {
                msg
            }
        } else {
            "Login failed"
        }
    }
    myLog("Sign in complete, message = $message")

    // Is the user logged in?
    val user = client.auth.currentUserOrNull()

    if (user != null) {
        myLog("User is logged in")
        CustomerSession.load(user)
    } else {
        myLog("User is not logged in")
    }

    return ServerLoginResponse(user != null, message)
}

suspend fun welcomeUser(): ServerLoginResponse {
    val client = HttpSession.getClient()
    val user = client.auth.currentUserOrNull()
    return if (user != null) {
        CustomerSession.load(user)
        val customer = CustomerSession.get()
        ServerLoginResponse(true, "Welcome back, ${customer.first_name}")
    } else {
        ServerLoginResponse(false, "You are not logged in")
    }
}