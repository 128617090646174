package model

import database.Units
import dev.fritz2.core.Lens
import dev.fritz2.core.Lenses
import dev.fritz2.core.lensOf
import kotlinx.serialization.Serializable
import kotlinx.serialization.json.Json
import kotlin.math.roundToInt

// Put your model data classes in here to use it on js and jvm side

val JSON_FORMAT = Json {
    encodeDefaults = true
    prettyPrint = true
    isLenient = true
    ignoreUnknownKeys = true
}




object Formats {
    val percent: Lens<Double, String> = lensOf( { (if (it % 1.0 == 0.0) (it * 100.0).roundToInt() else (it * 1000.0).roundToInt() / 10.0).toString() }, { it.toDouble() / 100.0 })
    val addSign: Lens<String, String> = lensOf( {it + "%"}, { if (it.last() == '%') it.dropLast(1) else it })
    val addUnitG: Lens<String, String> = lensOf( {it + "g"}, { if (it.last() == 'g') it.dropLast(1) else it })
    val addUnitKG: Lens<String, String> = lensOf( {it + "kg"}, { if (it.endsWith("kg")) it.dropLast(2) else it })
    val quantity: Lens<Double, String> = lensOf( { (if (it > 10.0) it.roundToInt() else (it * 10.0).roundToInt() / 10.0).toString() }, String::toDouble)
    val units: Lens<Units, String> = lensOf(Units::toString, Units::valueOf)
}

object UI {
    val hoverColor = "hover:bg-lime-200"
    val tooltipStyle = "px-2 py-1 bg-slate-800 rounded text-sm text-lime-300 z-[28] break-normal max-w-[180px] border border-lime-300"
    val tooltipArrowStyle = "h-4 w-4"
    val buttonStyle = "bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow leading-tight"
    val menuWidth = "w-40"
    val menuTranslate = "lg:translate-x-20" // half of width
    const val VERSION = "0.28"
    const val REQUIRE_LOGIN = true
    const val ALLOW_SIGNUP = true
    const val REQUIRE_ACCESS_CODE = false
    const val USE_DEV_DB = false
    const val USE_LOCALHOST = false // Use localhost for redirects
    const val SHOW_PRICING = false
}

@Lenses
data class ColumnHeadingFactoryParameterPack (
    val gridSpans: List<Int> = emptyList(),
    val labels:    List<String> = emptyList(),
    val tips:      List<String> = emptyList(),
)
{ companion object }

@Serializable
enum class AuthLevel {
    // This is an enum defined in the database. If you change it here, change it there.
    Free,
    Paid,
    Pro,
    Admin,
}

@Lenses
@Serializable
data class Customer (
    val id: Long = 0,
    val user_id: String = "",
    val auth_level : AuthLevel = AuthLevel.Free,
    val first_name: String = "",
    val last_name: String = "",
    val email: String = "",
    val start_year: Int = 2024, // Todo: Use current year
    val baking_level: BakingLevel = BakingLevel.beginner,
    val city: String = "",
)
{ companion object }

@Serializable
enum class BakingLevel {
    beginner,
    intermediate,
    advanced,
    pro,
}

// This is data in addition to UserLoginParams, which gets packaged into the data field of signup
@Lenses
@Serializable
data class NewCustomerData (
    val first_name: String = "",
    val last_name: String = "",
    val years_baking: Int = 0,
    val baking_level: BakingLevel = BakingLevel.beginner,
    val city: String = "",
    val access_code: String = "",
) { companion object }

// Stores the JSON strings for the formula and ingredient database as they are loaded to/from the database
// status is a result code. It's either "success" or an error message.
data class JsonStringPack (
    val formulaJson: String = "",
    val ingredientDbJson: String = "",
    val status: String = "",
)

