package session

import co.touchlab.kermit.Severity
import co.touchlab.kermit.loggerConfigInit
import io.github.jan.supabase.SupabaseClient
import io.github.jan.supabase.createSupabaseClient
import io.github.jan.supabase.gotrue.Auth
import io.github.jan.supabase.postgrest.Postgrest
import io.github.jan.supabase.serializer.KotlinXSerializer
import kotlinx.serialization.json.Json
import model.UI.USE_DEV_DB
import utils.myLog


object HttpSession {

    private val client: SupabaseClient by lazy {
        myLog("Creating SupabaseClient")
        val sUrl = if (USE_DEV_DB) "https://xeypgunsrpeoqiqvvxpr.supabase.co" else "https://oukjwygbyupfwdzjcidy.supabase.co"
        val sKey = if (USE_DEV_DB) "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InhleXBndW5zcnBlb3FpcXZ2eHByIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTgwODI2NTksImV4cCI6MjAzMzY1ODY1OX0.VaZ4vOAMha2Fky4ZtIn_hE_P94kqdAnpD60D6Ou-DQM"
                            else "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im91a2p3eWdieXVwZndkempjaWR5Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTkzNzMyMzMsImV4cCI6MjAzNDk0OTIzM30.JKdww6hNhETPq7pJLx1uybg1CzwsOT7_PaitiSGrzlI"
        myLog("HttpSession: connecting to sUrl = $sUrl with sKey = $sKey")

        try {
            createSupabaseClient( supabaseUrl = sUrl, supabaseKey = sKey ) {
                defaultSerializer = KotlinXSerializer(
                    Json {
                        prettyPrint = true
                        isLenient = true
                        ignoreUnknownKeys = true
                        loggerConfigInit(minSeverity = Severity.Error)
                    }
                )
                loggerConfigInit(minSeverity = Severity.Error)

                install(Postgrest)
                install(Auth)
            }

        } catch (e: Exception) {
            myLog("Error creating HttpSession: ${e.message}")
            throw e
        }

    }

    fun getClient(): SupabaseClient {
        return client
    }

}