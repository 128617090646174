package utils

import dev.fritz2.core.RenderContext
import dev.fritz2.core.Store
import dev.fritz2.core.storeOf
import dev.fritz2.headless.components.modal
import dev.fritz2.headless.components.switch
import kotlinx.browser.window
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.map
import interviews.interviewUI
import interviews.myTransition
import model.BfSeverity

external class Date {
    constructor()
    constructor(milliseconds: Number)

    fun getDate(): Int
    fun getMonth(): Int
    fun getHours(): Int
    fun getMinutes(): Int
}

object TimeFormat{
    /*
     * Returns a string with the current local time in this format: 1-Jan 13:00
     */
    fun formatLocalTime(): String {
        val currentDateTime = Date()
        val dayOfMonth = currentDateTime.getDate()
        val monthName = getMonthAbbreviation(currentDateTime.getMonth())
        val hours = currentDateTime.getHours().toString().padStart(2, '0')
        val minutes = currentDateTime.getMinutes().toString().padStart(2, '0')

        return "$dayOfMonth-$monthName ${hours}:${minutes}"
    }

    fun getMonthAbbreviation(monthIndex: Int): String {
        val months = arrayOf("Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec")
        return months[monthIndex]
    }
}

fun intToGridCols(i: Int): String {
    return when (i) {
        1 -> "grid-cols-1"
        2 -> "grid-cols-2"
        3 -> "grid-cols-3"
        4 -> "grid-cols-4"
        5 -> "grid-cols-5"
        6 -> "grid-cols-6"
        7 -> "grid-cols-7"
        8 -> "grid-cols-8"
        9 -> "grid-cols-9"
        10 -> "grid-cols-10"
        11 -> "grid-cols-11"
        12 -> "grid-cols-12"
        else -> "grid-cols-1"
    }
}

fun intToColSpan(i: Int): String {
    return when (i) {
        1 -> "col-span-1"
        2 -> "col-span-2"
        3 -> "col-span-3"
        4 -> "col-span-4"
        5 -> "col-span-5"
        6 -> "col-span-6"
        7 -> "col-span-7"
        8 -> "col-span-8"
        9 -> "col-span-9"
        10 -> "col-span-10"
        11 -> "col-span-11"
        12 -> "col-span-12"
        else -> "col-span-1"
    }
}

fun severityToTextColor(severity: BfSeverity): String {
    return when (severity) {
        BfSeverity.Info -> "text-info"
        BfSeverity.Warning -> "text-warning"
        BfSeverity.Error -> "text-error"
    }
}

fun alert(message: String) {
    val tstate = storeOf(true, job = Job())
    modal {
        this.openState(store = tstate)
        modalPanel(interviewUI.modalPanel) {
            modalOverlay(" ${interviewUI.modalOverlay}") {
                myTransition()
                modalTitle(interviewUI.modalTitle) { +"Hey!" }
                modalDescription(interviewUI.modalDescription) { +message }

                button("mt-4 ${interviewUI.cancelButton}") {
                    +"OK"
                    clicks handledBy {
                        tstate.update(false)
                    }
                }
            }
        }
    }
}

fun loginErrorAlert(message: String) {
    val tstate = storeOf(true, job = Job())
    modal {
        this.openState(store = tstate)
        modalPanel(interviewUI.modalPanel) {
            modalOverlay(" ${interviewUI.modalOverlay}") {
                myTransition()
                modalTitle(interviewUI.modalTitle) { +"Error" }
                modalDescription(interviewUI.modalDescription) { +message }

                button("mt-4 ${interviewUI.cancelButton}") {
                    +"OK"
                    clicks handledBy {
                        tstate.update(false)
                        window.location.reload()
                    }
                }
            }
        }
    }
}

fun RenderContext.switchTemplate(
    description: String,
    leftLabel: String,          // label to the left of the switch
    rightLabel: String,         // label to the right of the switch
    tState: Store<Boolean>,     // toggle state of the switch
    onOff: Boolean,             // true = on/off control, false = two equal choices
) {
    val switchToggleStyle =
        """relative inline-flex flex-shrink-0 h-6 w-11
                | cursor-pointer rounded-full
                | border-2 border-transparent ring-1 ring-lime-400
                | transition-colors ease-in-out duration-200
                | focus:outline-none focus:ring-4 focus:ring-lime-600""".trimMargin()

    if (description.isNotBlank()) {
        label("block text-sm font-semibold text-slate-800 mt-4 text-center mb-2") { +description }
    }
    span("flex-grow flex flex-row justify-center items-center") {
        if (leftLabel.isNotBlank()) {
            span("block mb-1 text-sm text-slate-800 mr-4") { +leftLabel }
        }

        switch(switchToggleStyle) {
            value(tState)
            className(enabled.map{ if (!it || onOff) "bg-lime-500" else "bg-lime-200" })
            span("inline-block h-5 w-5 rounded-full bg-white shadow pointer-events-none ring-0") {
                className(enabled.map { if (!it) "translate-x-5" else "translate-x-0" })
            }
        }
        changes handledBy { tState.update }

        if (rightLabel.isNotBlank()) {
            span("block mb-1 text-sm text-slate-800 ml-4") { +rightLabel }
        }
    }
}
