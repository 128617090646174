package auth

import io.github.jan.supabase.gotrue.auth
import session.HttpSession
import utils.myLog

suspend fun logoutUser(): Boolean {
    val client = HttpSession.getClient()
    var message = "Logout successful"

    try {
        client.auth.signOut()
    } catch (e: Exception) {
        myLog("Sign out exception: $e")
        message = e.message ?: "Logout failed"
    }
    myLog("Sign out complete, message = $message")

    val user = client.auth.currentUserOrNull()
    if (user != null) {
        myLog("User is still logged in")
    } else {
        myLog("User is not logged in")
    }

    return user == null
}


