package routing

import database.*
import dev.fritz2.core.RenderContext
import dev.fritz2.core.id
import dev.fritz2.core.storeOf
import dev.fritz2.headless.components.popOver
import dev.fritz2.headless.components.toastContainer
import dev.fritz2.headless.components.tooltip
import dev.fritz2.headless.foundation.utils.floatingui.utils.PlacementValues
import interviews.StateStore
import interviews.specialIngredientInterview
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.map
import main.appSettingsPanel
import main_panel.confirmAndSave
import model.UI
import utils.alert
import utils.myLog


fun RenderContext.menuCore() {
    val buttonStyle = "flex flex-row w-36 bg-white hover:bg-gray-100 text-sm py-1 pr-2 leading-tight justify-end"
    toastContainer("status", "w-40 h-12 absolute bottom-12 right-10 z-50")

    // Add ingredient button
    button("$buttonStyle xs:mt-1 lg:mt-4") {
        +"Add Ingredient"
        clicks handledBy FormulaStore.addIngredient
    }

    // Add stage button
    popOver {
        val tstate = storeOf(false)
        openState(store = tstate)
        data class poItem(val name: String, val stype: StageType, val description: String)

        val items = listOf(
            poItem(
                "Add preferment",
                StageType.preferment,
                "A preferment is any subproduct that is fermented with sourdough or yeast. Use for biga, poolish, levain, etc."
            ),
            poItem(
                "Add soaker",
                StageType.soaker,
                "A soaker is any subproduct that uses some ingredient soaked in a liquid."
            ),
            // poItem("Add autolyse", StageType.autolyse,"An autolyse typically includes all the remaining flour and water"),
            poItem(
                "Add final dough",
                StageType.final,
                "The final dough is the mix of all the remaining ingredients plus all the subproducts that haven't been consumed. This stage is automatically filled."
            ),
        )
        popOverButton(buttonStyle) {
            className(opened.map { if (it) "bg-gray-100" else "" })
            span { +"Add Stage" }
            // Apparently now popOverButton handles its own toggle clicks
            // clicks handledBy toggle
        }.tooltip(UI.tooltipStyle) {
            +"Add a soaker, preferment or final dough to the formula"
            arrow()
            placement = PlacementValues.right
        }

        popOverPanel("min-w-[280px] max-w-[350px] bg-white shadow-lg border border-slate-500 ml-4 rounded-lg") {
            placement = PlacementValues.rightEnd
            items.forEach { item ->
                a {
                    id(item.name)
                    div("rounded-md bg-white shadow-xs p-4 hover:bg-gray-100 cursor-pointer") {
                        p("font-semibold") { +item.name }
                        p("break-normal") { +item.description }
                    }
                }.clicks.map { item.stype } handledBy {
                    myLog("Menu requestStage: $it")
                    FormulaStore.requestStage(it)
                    tstate.update(false)
                }
            }
        }
    }

    // Calc button
    button(buttonStyle) {
        +"Calc"
        clicks handledBy FormulaStore.calc
    }


    // Convert menu
    popOver {
        val tstate = storeOf(false)
        openState(store = tstate)

        data class poItem(val name: String, val description: String)

        val items = listOf(
            poItem("Percent", "Calculate the percent for each ingredient based on the quantity."),
            poItem("Kitchen", "Convert from common kitchen measures to grams")
        )
        popOverButton(buttonStyle) {
            className(opened.map { if (it) "bg-gray-100" else "" })
            span { +"Convert" }
        }
        popOverPanel("min-w-[280px] max-w-[350px] bg-white shadow-lg border border-slate-500 ml-4 rounded-lg") {
            placement = PlacementValues.rightEnd
            items.forEach { item ->
                a {
                    id(item.name)
                    div("rounded-md bg-white shadow-xs p-4 hover:bg-gray-100 cursor-pointer") {
                        p("font-semibold") { +item.name }
                        p("break-normal") { +item.description }
                    }
                }.clicks.map { item.name.first().lowercase() } handledBy {
                    FormulaStore.calcDispatch(it)
                    tstate.update(false)
                }
            }
        }
    }

    // Save button
    button(buttonStyle) {
        +"Save"
        clicks handledBy {
            confirmAndSave()
        }
    }



    /* Load button via popOver */
    /*
     * When user clicks Load, we call the commonMain function getFormulaList(), which fetches the list of formulas
     * for this user from the database, then sends it to the front end selectFormula(list). That updates the listStore,
     * which causes the popOverPanel to render the list of formulas.
     *
     * When the user clicks on a formula, we call getFormula(fn), which fetches the formula from the database and sends
     * it to the front end loadFormula(f). That updates both the formulaStore, which causes the formula to be loaded
     * into the main panel, and the ingredient database.
     */
    popOver {
        val tstate = storeOf(false)
        openState(store = tstate)

        popOverButton(buttonStyle) {
            className(opened.map { if (it) "bg-gray-100" else "" })
            span { +"Load" }
            clicks handledBy { getFormulaList() }
        }

        popOverPanel("ml-8 grid grid-cols-1 align-start min-w-[250px] max-w-[450px] max-h-[350px] bg-white overflow-auto shadow-lg border border-gray-200") {
            placement = PlacementValues.rightEnd
            FormulaListService.listStore.data.render { theList ->
                theList.forEach { f ->
                    div("flex justify-between items-center w-full px-4 py-1 hover:bg-hover cursor-pointer text-xs border-t-2 border-gray-100/70") {
                        a("w-full") {
                            id(f)
                            +f
                        }.clicks.map { f } handledBy { fn ->
                            getFormula(fn)
                            tstate.update(false)
                        }
                        button("text-slate-200 hover:text-red-500 icon-[grommet-icons--trash] text-sm") {
                            clicks handledBy {
                                deleteFormula(f)
                            }
                        }
                    }
                }
            }
        }
    }


    // Settings
    button(buttonStyle) {
        +"Settings"
        clicks handledBy { appSettingsPanel() }
    }

    // Add Special Ingredient
    popOver {
        val tstate = storeOf(false)
        openState(store = tstate)

        data class poItem(val id: String, val name: String, val description: String, val signal: Char)

        val item = listOf(
            poItem(
                "extend",
                "Extend an ingredient",
                "New ingredient based on an existing ingredient (e.g. \"Soaker Water\" or \"Salt 2\")",
                'e'
            ),
            poItem("unique", "New unique ingredient", "Entirely new ingredient (e.g., \"banana chips\")", 'u'),
        )

        popOverButton(buttonStyle) {
            className(opened.map { if (it) "bg-gray-100" else "" })
            span { +"Add Special" }
        }.tooltip(UI.tooltipStyle) {
            +"Add a new ingredient to the database, which you can then use in your formula."
            arrow()
            placement = PlacementValues.right
        }


        popOverPanel("min-w-[280px] max-w-[350px] bg-white shadow-lg border border-slate-500 ml-4 rounded-lg") {
            placement = PlacementValues.rightEnd
            div("rounded-md bg-white shadow-xs p-4 text-center") {
                p("font-semibold") { +"Add Special Ingredient" }
                p("break-normal") { +"Add a new ingredient to the database, which you can then use in your formula." }
            }
            item.forEach { item ->
                a {
                    id(item.id)
                    div("rounded-md bg-white shadow-xs p-4 hover:bg-gray-100 cursor-pointer") {
                        p("font-semibold") { +item.name }
                        p("break-normal") { +item.description }
                    }
                }.clicks.map { item.signal } handledBy {
                    tstate.update(false)
                    specialIngredientInterview(it)
                }
            }
        }

    }

    // Print
    button(buttonStyle) {
        +"Print"
        clicks handledBy { alert("I don't know how to print yet") }
    }

    // Pricing
    if (UI.SHOW_PRICING) {
        popOver {
            val tstate = storeOf(false)
            openState(store = tstate)

            popOverButton(buttonStyle) {
                className(opened.map { if (it) "bg-gray-100" else "" })
                span { +"Pricing" }
            }

            data class poItem(val name: String, val page: Router.Page)

            val item = listOf(
                poItem("A", Router.Page.PRICING_A),
                poItem("B", Router.Page.PRICING_B),
                poItem("C", Router.Page.PRICING_C),
            )

            popOverPanel("min-w-[120px] max-w-[250px] bg-white ml-4") {
                placement = PlacementValues.rightEnd

                item.forEach { item ->
                    a {
                        id(item.name)
                        div("bg-white p-2 hover:bg-gray-100 cursor-pointer") {
                            p("font-semibold") { +item.name }
                        }
                    }.clicks.map { item.page } handledBy {
                        Router.routeTo(it)
                        tstate.update(false)
                    }
                }
            }
        }
    }

    /*
    button(buttonStyle) {
        +"Add rollup"
        clicks handledBy {
            val rollup = Ingredient(
                name            = "Fake rollup",
                qty             = 260.0,
                isFlour         = false,
                units           = Units.G,
                isRollup        = true,
                rollupStageKey  = "",
                isUsed          = false,
                saveThis        = true,
            )
            IngredientDatabaseStore.addIngredient(rollup)
        }
    }
    */

    button(buttonStyle) {
        +"Log stages"
        clicks handledBy {
            val stages = FormulaStore.current.stages
            myLog("Current stage list = ${stages.map{ it.name }}")
            myLog("StateStore = ${StateStore.current}")
            val newStageReq = FormulaStore.current.newStageRequest
            myLog("New stage request = $newStageReq")
            val db = IngredientDatabaseStore.current
            myLog("IngredientDatabaseStore.current: Current ingredient list size = ${db.ingredients.size}")
            val rollups = db.ingredients.filter { it.isRollup }
            myLog("IngredientDatabaseStore.current: Rollup ingredients = ${rollups.map { it.name }}")
        }
    }

    // Logout
    button(buttonStyle) {
        +"Logout"
        clicks handledBy Router.UserParamsStore.processLogout
    }

}

val menuVisibleStore = storeOf(false, job = Job())

fun RenderContext.buttonBlock() {
    menuVisibleStore.data.render { menuVisible ->
        myLog("menuVisible: $menuVisible")

        // Outer container
        div("fixed inset-0 flex flex-col z-10 h-full ${UI.menuWidth}") {

            // Hamburger menu button for small screens
            div("lg:hidden" + if (menuVisible) " hidden" else "") {
                button("bg-white hover:bg-gray-100 text-gray-800 py-2 px-4") {
                    id("sm_menu")
                    span("icon-[grommet-icons--menu] hamburger") {}
                    clicks.map { true } handledBy menuVisibleStore.update
                }
            }

            // Close button for small screens
            div("${UI.menuWidth} border-r border-solid border-black lg:hidden" + if (!menuVisible) " hidden" else "") {
                button("bg-white hover:bg-gray-100 text-gray-800 py-2 px-4") {
                    id("sm_close")
                    span("icon-[grommet-icons--close]") {}
                    clicks.map { false } handledBy menuVisibleStore.update
                }
            }

            // Menu for large screens, and for small screens when the hamburger is clicked
            div(
                "flex-grow ${UI.menuWidth} flex flex-col justify-start gap-2 h-full bg-white " +
                        "border-r border-solid border-black" +
                        (if (menuVisible) " xs:flex" else " xs:hidden") +
                        " lg:flex"
            ) {
                menuCore()
            }
        }
    }
}
